import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from "axios"

import "./service.css";
import "./specialmenu.css";

import TxtMenu from '../images/service/txt_menu.png'
import Motif_left from '../images/service/motif_left.png'
import Motif_right from '../images/service/motif_right.png'

import Overlay from "../components/common/overlay"
import Policy from '../components/common/policy';

const SpecialPage = () => {

  const [ visibleoverlay, setVisibleoverlay ] = useState(false);
  const [ nowLoading, setNowLoading ] = useState(false);
  const [ causedError, setcausedError ] = useState(false);
  const [ validate, setValidate ] = useState(false);
  const [ formValues, setFormValues ] = useState({
    subject: '星名ミリー特別メニュー 改名相談',
    category: '',
    firstname: '',
    familyname: '',
    gender: '',
    mail: '',
    confirmMail: '',
    request: '',
    agree: false
  });

  useEffect(() => {
    checkValidate(formValues)
  }, [formValues]);

  const handleClose = () => {
    setcausedError (false);
    setVisibleoverlay(false);
  }

  const handleInput = ({name, value, checked}) => {
    if (name === 'agree') {
      setFormValues({ ...formValues, [name]: checked });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  }
  
  const handleSubmit = event => {
    event.preventDefault();
    setNowLoading(true);
    setVisibleoverlay(true);

    // console.log('BEBUG: formValues are ', formValues);

    axios.post('https://ik-backend.herokuapp.com/onamae/contact', formValues)
    .then(response => {
      setNowLoading(false);
      console.log('response', response);
    })
    .catch(error => {
      setcausedError (true);
      setNowLoading(false);
      console.log('error', error);
    })
  }

  const checkValidate = values => {
    if (
       values.category.length > 0 &&
       values.familyname.length > 0 &&
       values.firstname.length > 0 &&
       values.gender.length > 0 &&
       values.mail.length > 0 &&
       values.confirmMail.length > 0 &&
       values.mail === values.confirmMail &&
       values.agree === true
    ) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }

  return (
    <Layout>
      <SEO title="SPECIAL MENU" />
      <div className="service specialmenu">
        <div className="fifth" id="service">
          <ul className="menu">
            <li>
              <div className="detail">
                <p className="ribbon">COURSE</p>
                <div className="head-box">
                  <p className="txt-icon">
                    <img alt=" " src={TxtMenu} className="scale-to-fit" />
                  </p>
                  <p className="en">SPECIAL MENU</p>
                  <h2 className="special-title ff-min">星名ミリー特別メニュー</h2>
                </div>
                <div className="panel">
                  <div className="special-head">
                    <p>
                      <img alt=" " src={Motif_left} className="scale-to-fit" />
                    </p>
                    <h3 className="ff-min">個人鑑定</h3>
                    <p>
                      <img alt=" " src={Motif_right} className="scale-to-fit" />
                    </p>
                  </div>
                  <ul>
                    <li>基本オンライン講座となります</li>
                    <li>＜所要時間＞60分</li>
                    <li>＜料金＞33,000円（税込）</li>
                  </ul>
                  <p className="read">
                    初回33,000円（税込）ですが、２回目以降は16,500円（税込）です。<br />
                    年間会員 132,000円 （税込）１ヶ月２回まで鑑定可能となっております。最大12ヶ月(24回×60分）
                  </p>
                  <p className="read">
                    あなたの名前から個性、才能、本質を分析し、明確にお伝えします。悩みを整理し、人生をより良く、理想的に加速させたい方にオススメです。
                  </p>
                  <Policy />
                  <div className="btn-holder ff-min">
                    <a href="mailto:hello.millyhoshina@gmail.com?subject=個人鑑定の申込み">お申し込みはこちら</a>
                  </div>
                </div>
                <div className="panel">
                  <div className="special-head">
                    <p>
                      <img alt=" " src={Motif_left} className="scale-to-fit" />
                    </p>
                    <h3 className="ff-min">改名相談</h3>
                    <p>
                      <img alt=" " src={Motif_right} className="scale-to-fit" />
                    </p>
                  </div>
                  <ul>
                    <li>基本オンライン講座となります</li>
                    <li>＜料金＞<br />
                      芸名、ビジネスネーム 110,000円（税込）<br />
                      会社名や商品名 220,000円（税込）
                    </li>
                  </ul>
                  <p className="read">
                    ＜相談の流れ＞<br />
                      1. 方向性やご希望をヒアリング（60分）<br />
                      2. 3つの名前を提案します。<br />
                      3. 最終ヒアリング（60分）<br />
                      4. お渡し（改名、命名鑑定書をデータでお渡し致します）
                  </p>
                  <form method="POST" onSubmit={handleSubmit}>
                    <div className="special-form">
                      <div className="head">
                        （※１）芸名、ビジネスネームの方は現在お使いの苗字、名前を入れてください。
                      </div>
                      <div className="body">
                        <div className="block">
                          <div className="key">改名希望するのは</div>
                          <div className="value">
                            <label>
                              <input type="radio" name="category" value="芸名" onInput={event => handleInput(event.target)} />
                              芸名
                            </label>
                            <label>
                              <input type="radio" name="category" value="ビジネスネーム" onInput={event => handleInput(event.target)} />
                              ビジネスネーム
                            </label>
                            <label>
                              <input type="radio" name="category" value="会社名や商品名" onInput={event => handleInput(event.target)} />
                              会社名や商品名
                            </label>
                          </div>
                        </div>
                        <div className="block">
                          <div className="key">あなたの苗字(＊1)</div>
                          <div className="value">
                            <input type="text" name="familyname" onInput={event => handleInput(event.target)} />
                          </div>
                        </div>
                        <div className="block">
                          <div className="key">あなたの名前(＊1)</div>
                          <div className="value">
                            <input type="text" name="firstname" onInput={event => handleInput(event.target)} />
                          </div>
                        </div>
                        <div className="block">
                          <div className="key">性別</div>
                          <div className="value gender">
                            <label>
                              <input type="radio" name="gender" value="男性" onInput={event => handleInput(event.target)} />
                              男
                            </label>
                            <label>
                              <input type="radio" name="gender" value="女性" onInput={event => handleInput(event.target)} />
                              女
                            </label>
                          </div>
                        </div>
                        <div className="block">
                          <div className="key">メールアドレス</div>
                          <div className="value">
                            <input type="email" name="mail" onInput={event => handleInput(event.target)} />
                          </div>
                        </div>
                        <div className="block">
                          <div className="key">メールアドレス<br />(確認用)</div>
                          <div className="value">
                            <input type="email" name="confirmMail" onInput={event => handleInput(event.target)} />
                          </div>
                        </div>
                        <div className="block">
                          <div className="key">希望や要望、職種や付けて欲しい文字やご希望など</div>
                          <div className="value">
                            <textarea name="request" rows="5" onInput={event => handleInput(event.target)}>
                            </textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="special-caution">
                      <p>確認事項</p>
                      <ul>
                        <li>入金確認後、5営業日以内に登録したメールアドレスに返信されます。</li>
                        <li>携帯電話で迷惑メール対策の設定をしていますと､そのままではメールが届かない場合があります｡ 迷惑メール対策をされている方は､ 設定の変更をお願いいたします｡</li>
                        <li>ドメイン指定受信を設定している方は、<br />
                        <span className="mail-address">「hello.millyhoshina@gmail.com」</span>
                        を指定してください。</li>
                      </ul>
                    </div>
                    <div className="special-check">
                      <label>
                        <input type="checkbox" name="agree" value="true" onInput={event => handleInput(event.target)} />
                        確認事項に同意して命名依頼します。
                      </label>
                    </div>
                    <Policy />
                    <div className="btn-holder ff-min">
                      <button type="submit" disabled={!validate} className="special-link">お申し込みはこちら</button>
                    </div>
                  </form>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      { visibleoverlay ?
        <Overlay nowLoading={nowLoading} error={causedError} handleClose={handleClose} />
        :
        null
      }
    </Layout>
  )
}

export default SpecialPage
