import React from "react"
import { Link } from "gatsby"

import "./overlay.css";

const Overlay = ({ nowLoading, error, handleClose }) => (
  <div className="overlay">
    <div className="content">
      { nowLoading ? 
          <div className="loader">Loading...</div>
        : 
          error ?
            <div className="result error">
              <div className="message">
                <h1>エラーが発生しました</h1>
                <p>お手数ですが、再度お問い合わせフォームより送信してください。</p>
              </div>
              <div className="btn-holder">
                <button onClick={handleClose}>閉じる</button>
              </div>
            </div>
          :
            <div className="result success">
              <div className="message">
                <h1>お問い合わせを承りました</h1>
                <p>ご記入のメールアドレスにメールを送信しましたので、ご確認ください。</p>
              </div>
              <div className="btn-holder">
                <Link to='/'>トップページへ</Link>
              </div>
            </div>
      }
    </div>
  </div>
)

export default Overlay
